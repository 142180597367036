import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <div>
      <h1>404 Error</h1>
      <Link to="/">Go back to the home page</Link>
    </div>
  );
};
export default Error;
